import { Link } from 'gatsby'
import { Seo } from '~/components'

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404: Not Found" />
      <div className="pageComponent">
        <article
          className="notFoundPage"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header className="titleComponent contentComponent">
            <h1 className="hidden-phone heading pageTitle">404 NOT FOUND</h1>
            <h1 className="visible-phone heading pageTitle">
              404
              <br />
              NOT FOUND
            </h1>
          </header>
          <div className="contentComponent">
            <p>恐れ入りますが、ページが見つかりませんでした</p>
            <Link className="moreLink" to="/">
              <span className="heading is-secondary">TOP</span>
            </Link>
          </div>
        </article>
      </div>
    </>
  )
}

export default NotFoundPage
